.bg-revisa-prev{
    background-color: #3B397F;
}

.color-revisa-prev{
    color: #00C29E;
}

.destaque-variavel{
    border: 1px solid #696969;
    border-radius: 10px;
    padding: 5px;
    background-color: #DCDCDC;
    font-size: 13px;
    font-style: italic;
}
